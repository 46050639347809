import React, { FC } from 'react';
import { OurWorkSection1 } from '@/api/our-work';
import { Container, Grid, Typography } from '@mui/material';
import { MarkdownContent } from '@/components/markdown-content';
import { MainColumn } from '@/components/main-column';
import { MENU_HEIGHT } from '@/constants';

type IOurWorkSection1 = {
  strapiSection: OurWorkSection1;
};

export const Section1: FC<IOurWorkSection1> = ({ strapiSection }) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{
      backgroundColor: 'greyscale.cultured',
      display: 'flex',
      alignItems: 'center',
      mt: `${MENU_HEIGHT}px`,
      minHeight: {
        sm: '372px',
        md: '448px',
      },
    }}
  >
    <MainColumn>
      <Grid
        xs={12}
        sx={{
          maxWidth: '1440px',
        }}
        container
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={12} md={6}>
          <MarkdownContent
            content={strapiSection?.paragraph}
            sx={{ fontWeight: 'semiBold', marginBlockStart: '1.5rem' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ mb: { md: 0, xs: 9 } }}>
          <Typography> {strapiSection?.text} </Typography>
        </Grid>
      </Grid>
    </MainColumn>
  </Container>
);
