import React, { FC } from 'react';

interface ILinkArrow {
  rotation?: number;
}

export const LinkDownArrow: FC<ILinkArrow> = ({ rotation = 0 }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ transform: `rotate(${rotation}deg)` }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.66669C16 5.1144 15.5523 4.66669 15 4.66669C14.4477 4.66669 14 5.1144 14 5.66669L14 12.5858L1.70645 0.292401C1.31592 -0.0981217 0.682759 -0.0981184 0.292237 0.292408C-0.0982857 0.682934 -0.0982819 1.3161 0.292244 1.70662L12.5861 14.0004L5.66623 14.0004C5.11395 14.0004 4.66623 14.4481 4.66623 15.0004C4.66623 15.5526 5.11395 16.0004 5.66623 16.0004L15 16.0004C15.5523 16.0004 16 15.5526 16 15.0004L16 5.66669Z"
      fill="#022539"
    />
  </svg>
);
