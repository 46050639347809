import React, { FC } from 'react';
import { Grid, useTheme } from '@mui/material';

import { CaseStudyCard, CaseStudyCardVariants } from '@/components/case-study';
import { OurWorkSection2 } from '@/api/our-work';
import { MainColumn } from '@/components/main-column';

type IOurWorkSection2 = {
  strapiSection: OurWorkSection2;
};

export const Section2: FC<IOurWorkSection2> = ({ strapiSection }) => {
  const theme = useTheme();
  return (
    <MainColumn>
      <Grid
        sx={{
          backgroundColor: 'greyscale.white',
          marginTop: 6,
          paddingTop: 1,
          paddingBottom: 1,
        }}
        container
        justifyContent={{
          sm: 'center',
          md: 'space-between',
          lg: 'space-between',
        }}
      >
        {strapiSection?.ourWork?.data
          ?.filter((caseStudy) => !!caseStudy)
          ?.map(
            (
              {
                attributes: {
                  slug,
                  icon,
                  isSeparateSubpage,
                  gradientColor,
                  description,
                  pills,
                  name,
                  image,
                },
              },
              index,
            ) => {
              const url = image?.data?.attributes?.url || '';
              return (
                <CaseStudyCard
                  key={index}
                  isSeparateSubpage={isSeparateSubpage}
                  index={index}
                  name={name}
                  pills={pills}
                  variant={CaseStudyCardVariants.OurWorkMain}
                  gradientColor={
                    gradientColor ||
                    theme.palette.greyscale.persian
                  }
                  slug={slug}
                  icon={icon.data}
                  url={url}
                  description={description}
                />
              );
            },
          )
        }
      </Grid>
    </MainColumn>
  );
};
