import React, { FC, useCallback, useEffect, useState } from 'react';
import { OurWorkSection3 } from '@/api/our-work';
import { MainColumn } from '@/components/main-column';
import { Container, Grid, Link, Typography, useTheme } from '@mui/material';
import { CaseStudyCard, CaseStudyCardVariants } from '@/components/case-study';
import { MarkdownContent } from '@/components/markdown-content';
import { LinkDownArrow } from '@/icons/LinkDownArrow';
import { HomeCaseStudy } from '@/api/home';

type IOurWorkSection3 = {
  strapiSection: OurWorkSection3;
};

export const Section3: FC<IOurWorkSection3> = (section) => {
  const strapiSection = section?.strapiSection;
  const theme = useTheme();
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [projectsToShow, setProjectsToShow] = useState<HomeCaseStudy[]>();

  const isAllProjectsShown =
    strapiSection?.projects?.data?.length === projectsToShow?.length;

  const loadInitialProjects = useCallback(() => {
    const first6ProjectsToShow = strapiSection?.projects?.data
      ?.filter(
        (project, idx) => idx < 6,
      );
    setProjectsToShow(first6ProjectsToShow);
  }, [strapiSection?.projects?.data]);

  useEffect(() => {
    loadInitialProjects();
  }, [loadInitialProjects]);

  const loadMoreProjects = () => {
    const nextProjects = strapiSection?.projects?.data;
    setProjectsToShow(nextProjects);
  };

  const renderCards = useCallback(
    () =>
      projectsToShow?.map(
        (
          {
            attributes: {
              isSeparateSubpage,
              gradientColor,
              slug,
              name,
              description,
              icon,
              image,
            },
          },
          index,
        ) => (
          <CaseStudyCard
            key={`${image?.data?.attributes?.url || ''}`}
            gradientColor={gradientColor || theme.palette.greyscale.persian}
            description={description}
            expanded={selectedCards.includes(index)}
            isSeparateSubpage={isSeparateSubpage}
            onClick={() => {
              const indexOfElement = selectedCards.indexOf(index);
              if (indexOfElement > -1) {
                selectedCards.splice(indexOfElement, 1);
              } else {
                selectedCards.push(index);
              }
              setSelectedCards([...selectedCards]);
            }}
            slug={slug}
            variant={CaseStudyCardVariants.OurWorkOther}
            name={name}
            icon={icon?.data}
            url={image?.data?.attributes?.url || ''}
          />
        ),
      ),
    [projectsToShow, selectedCards],
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor: 'greyscale.cultured',
        display: 'flex',
        alignItems: 'center',
        mt: 6,
      }}
    >
      <MainColumn
        sx={{
          backgroundColor: 'greyscale.cultured',
        }}
      >
        <Grid
          xs={12}
          sx={{
            maxWidth: '1440px',
          }}
          container
          flexDirection={'row'}
        >
          <Grid item xs={12} sm={12} md={6}>
            <MarkdownContent
              content={strapiSection?.paragraph}
              sx={{ fontWeight: 'semiBold', my: { md: 9, xs: 5, sm: 7 } }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          columnSpacing={{ xs: 0, sm: 4 }}
          sx={{
            mb: { md: 6, xs: 2, sm: 3 },
          }}
        >
          {renderCards()}
        </Grid>
        {!isAllProjectsShown ? (
          <Link
            component={Typography}
            onClick={loadMoreProjects}
            sx={{
              mb: { md: 9, xs: 5, sm: 7 },
              color: 'greyscale.persian',
            }}
          >
            {strapiSection?.link?.text} <LinkDownArrow />
          </Link>
        ) : (
          <Link
            component={Typography}
            onClick={loadInitialProjects}
            sx={{
              mb: { md: 9, xs: 5, sm: 7 },
              color: 'greyscale.persian',
            }}
          >
            Show Less Projects <LinkDownArrow rotation={270} />
          </Link>
        )}
      </MainColumn>
    </Container>
  );
};
