import React from 'react';
import { StrapiMenu } from '../../api/menu';
import { Menu } from '@/components/menu';
import { StrapiFooter } from '../../api/footer';
import { Footer } from '@/components/footer';
import { Section1 } from '@/components/sections/our-work/Section1';
import { StrapiOurWork } from '../../api/our-work';
import { Section2 } from '@/components/sections/our-work/Section2';
import { Section3 } from '@/components/sections/our-work/Section3';
import { Head as HeadComponent } from '@/components/head';

export default function OurWork({
  pageContext: {
    strapiMenu,
    strapiFooter,
    strapiOurWork: {
      metadata,
      section1,
      section2,
      section3 },
  },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiOurWork: StrapiOurWork;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      title: 'Client Work | Treeline Interactive',
      description: 'Treeline client work portfolio',
      keywords: 'client work, portfolio',
    },
  };
  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={metadata}
        image={metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <Menu strapiMenu={strapiMenu} />
      <Section1 strapiSection={section1} />
      <Section2 strapiSection={section2} />
      <Section3 strapiSection={section3} />
      <Footer strapiFooter={strapiFooter} />
    </>
  );
}
